<template>
  <!-- 新闻资讯 -->
  <div class="xx_trends">
    <div class="xx_trends_bg">
      <div
        class="big"
        :style="{
          backgroundImage: 'url(' + region_bg + ')'
        }"
      >
        <img
          :src="region_bg"
          alt=""
          style="width:100%"
        />
      </div>
    </div>
    <ul class="xx_trends_type">
      <a
        :href="'#/xx_trends?currentIdx=' + head_active"
        v-for="(item, idx) in sjHead"
        :key="idx"
        :class="[head_active == idx ? 'xz_type' : '']"
        @click="switchList(item, idx)"
      >
        {{ item.name }}
      </a>
    </ul>
    <div class="xx_business_nav" v-if="!currentId">
      <ul class="xx_trends_list" v-if="sjList.length != 0">
        <li v-for="(item, idx) in sjList" :key="idx" @click="goDetails(item)">
          <a :href="'#/xx_trends?currentIdx=' + head_active + '&currentId=' + item.id">
            <div class="li_title">
            <span style="color: #d4237a;margin-right: 0.5rem;">{{
              idx + 1 + 6 * (page - 1)
            }}</span>
            <span>{{ item.title }}</span>
          </div>
          <div class="li_text ellipsis">
            {{ item.miaoshu }}
          </div>
          </a>
        </li>
        <onLoading :show="loading"></onLoading>
      </ul>
      <div class="details_empty" v-else>
        <img
          src="@/assets/image/xwdt/xwdt-data.webp"
          alt=""
          style="margin:0 auto"
        />
      </div>
      <div class="all-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          background
          :page-size="6"
          layout="prev, pager, next,total"
          :total="total"
          v-if="total > 6"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </div>
    <div class="xx_business_nav2" v-else>
      <div class="nav2_html" v-html="sjData.maincontent"></div>
    </div>
  </div>
</template>

<script>
import { getXwTypeData, getAllXwData, getXwxqData } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      region_bg: require('@/assets/image/all/nybaner.jpg'),
      sjHead: [],
      sjList: [],
      head_active: 0,
      type_id: '',
      project: '',
      page: 1,
      total: 0,
      loading: false,
      currentId: 0,
      sjData: {}
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.head_active = this.$route.query.currentIdx
        } else {
          this.head_active = 0
          this.getSjdqList(1, '', this.project)
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      if (that.$route.query.currentIdx) {
        that.head_active = that.$route.query.currentIdx
      } else {
        that.head_active = 0
      }
      if (this.$route.query.currentId) {
        this.currentId = this.$route.query.currentId
        this.goDetails({ id: this.currentId })
      } else {
        this.currentId = 0
      }
      let id = sessionStorage.getItem(that.changeData() + 'id')
      that.project = sessionStorage.getItem(that.changeData() + 'project')
      getXwTypeData(1, that.project).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          that.sjHead = res.data.data
        }
      })
      that.getSjdqList(1, '', that.project)
    },
    getSjdqList (i, x, d) {
      this.loading = true
      getAllXwData(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.sjList = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getSjdqList(val, this.type_id, this.project)
    },
    switchList (e, i) {
      this.head_active = i
      this.type_id = e.id
      this.currentId = 0
      this.getSjdqList(1, e.id, this.project)

    },
    goDetails (e) {
      this.currentId = e.id
      this.sjData = {}
      getXwxqData(e.id).then(res => {
        if (res.data.code == 1) {
          this.sjData = res.data.data
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.xx_trends {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .xx_trends_bg {
    overflow: hidden;
    position: relative;
    .big {
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: 70% center;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 5rem;
      animation: scaleAnim 5s ease forwards;
      img {
        width: 100%;
        opacity: 0;
      }
    }
  }
  .xx_trends_type {
    width: 15rem;
    background-color: #fff;
    margin: 3% auto;
    margin-bottom: 1%;
    border-bottom: 1px solid #d4237a;
    text-align: center;
    display: flex;
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      font-size: 0.18rem;
      color: #333333;
      position: relative;
      text-align: center;
      width: 1.5rem;
      padding: 0.3rem 0.15rem;
      cursor: pointer;
    }
    a:hover,
    .xz_type {
      background-color: #d4237a;
      color: #fff;
    }
  }
  .xx_trends_list,
  .details_empty {
    width: 15rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    li {
      padding: 2.88% 4% 2.88% 2%;
      font-size: 0.16rem;
      width: calc(100% - 6%);
      background-color: #fff;
      margin-top: 2%;
      transition: all 0.3s linear;
      .li_title {
        cursor: pointer;
        font-size: 0.19rem;
        color: #333333;
        font-weight: 600;
      }
      .li_title:hover {
        color: #d4237a;
      }
      .li_text {
        margin-top: 0.2rem;
      }
    }
    li:hover {
      box-shadow: 0 0 0.15rem rgba(0, 0, 0, 0.1);
    }
  }
  .details_empty {
    background-color: #fff;
  }
  .xx_business_nav2 {
    width: 1200px;
    margin: 50px auto;
    .nav2_html{
      line-height: 1.5;
      /deep/img {
        margin: 0 auto;
        display: block;
      }
    }
  }
  .all-pagination {
    padding-bottom: 35px;
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
